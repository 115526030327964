<template>
  <div>
    <b-modal
      id="modal-edit-dress"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('EditD')"
      hide-footer
      @click="onReset"
    >
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="`${$t('Name')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            aria-describedby="input-1-live-feedback"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group />

        <b-form-group
          :label="$t('Category')"
          style="width: 100%;"
        >
          <vue-select
            v-model="form.lovDressCategoryId"
            :options="getDressCategory"
            label="name"
            :reduce="(e) => e.lovDressCategoryId"
            :placeholder="$t('SelectCategory')"
            aria-describedby="input-1-live-feedback"
            :clearable="false"
          />
          <b-form-invalid-feedback
            id="input-1-live-feedback"
          >{{ $t('This_is_a_required_field_and_must_be_at_least_3_letters') }}</b-form-invalid-feedback>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          :label="`${$t('Code')}:`"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.code"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>

        <div class="buttonsEverywhere">
          <button
            type="button"
            variant="none"
            class="buttonSubmit"
            style="margin-top: 10px;"

            @click="onSubmit"
          >
            {{ $t('Submit') }}
          </button>
          <b-button
            type="button"
            variant="danger"
            class="buttonCancel"
            style="margin-right: 15px; margin-top: 10px; background: white; color: black; border-color: white; font-weight: 400;"

            @click="onReset"
          >
            {{ $t('Cancel') }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import {
  required, minLength,
} from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';


export default {
  mixins: [validationMixin],

  props: {
    // eslint-disable-next-line vue/require-default-prop
    dress: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        created: '',
        id: '',
        name: '',
        lovDressCategoryId: null,
        code: null,
      },
    }
  },
  validations: {
    form: {
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    ...mapGetters(['getDressCategory']),
  },
  watch: {
    editedMaterial() {
      this.name()
    },
  },
  mounted() {
    this.form = this.dress
  },
  methods: {
    ...mapActions(['dressCategories']),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    name() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.form.name = this.editedMaterial.name
      this.form.description = this.editedMaterial.description
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.$emit('updateDress', this.form)
      this.$refs.modal.hide()
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },

    onReset() {
      this.$refs.modal.hide()
      this.form.name = ''
      setTimeout(() => { this.$v.$reset() }, 0)
      this.$nextTick(() => { this.$v.$reset() })
    },
  },
}
</script>

<style lang="scss" scoped>
  // form {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   gap: 10px;
  // }
  // .flex-form {
  //   display: flex;
  //   flex-direction: column;
  // }
</style>
